/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import type { AddToCartData } from '@/types';
import { eventConstants } from '@/constants/event';
import { NavBar } from '@/components/NavBar';
import { trackAddToCartCertonaCartridge } from '@/utils/analytics/track/trackAddToCartCertonaCartridge';
import usePrevious from '@/utils/usePrevious';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import type { SkuPricingAndAvailabilityFromAPI, AZBadgesFlagVOFromAPI } from '@/types/availability';
import type { LineItemFromState, OrdersDetailsFromState } from '@/types/reduxStore/orders';
import PriceComponent from '@/components/AZCustomComponent/Price';
import Image from '@/components/NextImage';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { imageConstant } from '@/constants/images';
import { countryCodes } from '@/constants/locale';
import MiniCartFlyoutCheckout from './MiniCartFlyoutCheckout';
import { CertonaBlock } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import { useFeatureFlag } from '@/features/globalConfig';
import { type CartSummary } from '@/features/header';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import type { AddToCartResponse, CartSuccess } from '@/types/reduxStore/cartUpdate';
import azCommonStyles from '@/theme/globals.module.scss';
import { useDeviceType } from '@/utils/useDeviceType';
import cx from 'classnames';
import { useEffect, useState, type RefObject } from 'react';
import styles from './styles.module.scss';
import MiniCartFlyoutItemCard from './MiniCartFlyoutItemCard';
import MiniCartFlyoutDealImage from './MiniCartFlyoutDealImage';
import { StackedPartCardSkeleton } from '@/features/certona';
import { formatPartTypeId } from '@/utils/formatPartTypeId';
import { formatPrice } from '@/utils/validator/formatPrice';
import type { PageName } from '@/types/analytics';

const certonaMinicartObj = {
  '@type': 'MiniCart_Certona',
  containerID: 'addtocart',
  desktop: true,
  mobile: true,
  name: 'Certona Content Block',
  tablet: true,
  themeCode: 'white',
};

const certonaDealsMinicartObj = {
  '@type': 'MiniCart_Certona',
  containerID: 'dealsdraweraddtocart_rr',
  desktop: true,
  mobile: true,
  name: 'Certona Content Block',
  tablet: true,
  themeCode: 'white',
};

type ProductDetails = {
  productName?: {
    productBrand: string | undefined;
    productPart: string | undefined;
    quickNote: string | undefined;
  };
  productPartType?: string | undefined;
  productId?: string | undefined;
  productAvailabilityInfo?: {
    skuPricingAndAvailability: SkuPricingAndAvailabilityFromAPI | undefined;
    dealsInfo: unknown | undefined;
    aZBadgesFlagVO: AZBadgesFlagVOFromAPI | undefined | null;
  };
  certonaItemAddedTocart?: boolean;
};

type Props = {
  handleClose: () => void;
  isModalOpen: boolean;
  closeStoreModal: () => void;
  successData:
    | (CartSuccess & { originalPartTypeId?: string })
    | (AddToCartResponse & { quantity: string })
    | null
    | undefined;
  miniCartData: CartSummary | undefined;
  image: string;
  productDetails: ProductDetails;
  cartUrl: () => void;
  productTitle: string;
  pageName: PageName;
  orderDetailsFromState: OrdersDetailsFromState;
  miniCartDealData?: Set<string>;
  skuId?: string | undefined;
  parentRef: RefObject<HTMLDivElement | undefined>;
};

const labelMap: Record<string, string> = {
  lblMiniCartAddedToCart: 'label_mini_cart_added_to_Cart',
  lblMiniCartQty: 'label_mini_cart_qty',
  lblMiniCartCorePrice: 'label_mini_cart_core_price',
  lblCart: 'label_cart_topNavigation_Cart',
  lblMiniCartSubTotal: 'label_mini_cart_sub_total',
  lblMiniCartContinueShop: 'label_mini_cart_continue_shopping',
  lblMiniCartItem: 'label_mini_cart_item',
  lblMiniCartItems: 'label_mini_cart_items',
};

export function MiniCartFlyoutComp(props: Props) {
  const { data: storeDetails } = useStoreDetailsData();
  const { data: headerData } = useHeaderData();
  const [itemAddedToCart, setItemAddedToCart] = useState(props.successData);
  const [hasLoadedCertona, setHasLoadedCertona] = useState(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const deviceType = useDeviceType();
  const isABot = deviceType === 'bot';
  const { data: shelfData } = useProductList();
  const parts = shelfData?.shelfParts ?? [];
  const locale = useLocale();
  const vehicleId = headerData?.vehicleMap.catalogVehicleId || '';
  const {
    image,
    productTitle,
    productDetails,
    miniCartData,
    isModalOpen,
    handleClose,
    miniCartDealData,
    skuId: skuIdFromProductDetail,
  } = props;

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isDealsDrawerMiniCartEnabled =
    useFeatureFlag('IS_DEALS_DRAWER_MINI_CART_ENABLED') === 'true';
  const desktopMiniCartFlyoutEnabled =
    useFeatureFlag('IS_DESKTOP_MINI_CART_FLYOUT_ENABLED') === 'true';
  const mobileMiniCartFlyoutEnabled =
    useFeatureFlag('IS_MOBILE_MINI_CART_FLYOUT_ENABLED') === 'true';
  const tabletMiniCartFlyoutEnabled =
    useFeatureFlag('IS_TABLET_MINI_CART_FLYOUT_ENABLED') === 'true';
  const isMiniCartFlyoutEnabled =
    (isDesktop && desktopMiniCartFlyoutEnabled) ||
    (isMobile && mobileMiniCartFlyoutEnabled) ||
    (isTablet && tabletMiniCartFlyoutEnabled);
  const certonaAddToCartEnabled = !isABot;
  const isUseDealsCart = isDealsDrawerMiniCartEnabled && miniCartDealData !== undefined;

  const skuIds = skuIdFromProductDetail ? [skuIdFromProductDetail] : null;
  const skuDetailsResult = useProductSkuDetails({
    skuIds,
  });

  useEffect(() => {
    window.certonaNotCalled = true;
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === eventConstants.escKeyCode) {
        handleClose();
      }
    };

    document.addEventListener('keyup', handleKeyDown);
    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, [isModalOpen, handleClose]);

  const labels = useLabels(labelMap);

  const skuId =
    ((!miniCartDealData?.size &&
      itemAddedToCart &&
      'skuId' in itemAddedToCart &&
      itemAddedToCart.skuId) ||
      productDetails.productAvailabilityInfo?.skuPricingAndAvailability?.skuId ||
      skuIdFromProductDetail) ??
    '';

  const addToCartItem =
    isABot && parts ? parts[0] : parts.find((item) => String(item.skuNumber) === skuId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const addedProductImage =
    // this should be done by refactoring getProductList into separate hooks that are used by components directly
    // SEB-7290
    addToCartItem?.itemImageUrl ??
    (itemAddedToCart && 'imageUrl' in itemAddedToCart ? itemAddedToCart.imageUrl : undefined) ??
    addToCartItem?.imageUrl ??
    addToCartItem?.productImageUrl ??
    image ??
    '';
  const addedProductTitle =
    addToCartItem?.name ||
    (itemAddedToCart && 'label' in itemAddedToCart ? itemAddedToCart.label : undefined) ||
    productTitle ||
    '';
  const addedProductDetails = {
    productAvailabilityInfo: {
      skuPricingAndAvailability: skuIdFromProductDetail
        ? skuDetailsResult.data?.[0].skuPricingAndAvailability
        : addToCartItem?.skuPricingAndAvailability ||
          productDetails.productAvailabilityInfo?.skuPricingAndAvailability,
    },
  };

  const findMiniCartItemBySkuId = (skuId: string) => {
    const orders = props.orderDetailsFromState.orderItems;
    if (!orders) {
      return null;
    }

    let foundLineItem: LineItemFromState | undefined;
    orders.find((order) => {
      order.shipmentInfoList.find((shipment) => {
        shipment.lineItemList.find((lineItem) => {
          if (lineItem.productInfo.skuId === skuId) {
            foundLineItem = lineItem;
            return true;
          }
        });

        if (foundLineItem) {
          return true;
        }
      });

      if (foundLineItem) {
        return true;
      }
    });
    return foundLineItem;
  };

  const findMiniCartDataBySku = (skuId: string): { itemImage: string; quantity: string } => {
    const cartOrders = props.orderDetailsFromState.orderItems;
    const dealData: { itemImage: string; quantity: string } = { itemImage: '', quantity: '0' };

    cartOrders.find((order) => {
      order.shipmentInfoList.find((shipment) => {
        shipment.lineItemList.find((lineItem) => {
          if (lineItem.productInfo.skuId === skuId) {
            dealData.itemImage = lineItem.productInfo.imageUrl;
            dealData.quantity = lineItem.itemQuantity;
            return true;
          }
        });
        if (dealData.quantity !== '0') {
          return true;
        }
      });
      if (dealData.quantity !== '0') {
        return true;
      }
    });
    return dealData;
  };
  const isDeal =
    miniCartDealData && isDealsDrawerMiniCartEnabled && miniCartDealData.size > 1 && !skuId;

  const getMiniCartItem = () => {
    if (!isDeal && miniCartDealData?.size === 1) {
      const [dealItemSku] = miniCartDealData;
      return findMiniCartItemBySkuId(dealItemSku);
    } else {
      return findMiniCartItemBySkuId(skuId);
    }
  };
  const miniCartItem = getMiniCartItem();

  const total = Number(miniCartItem?.itemQuantity ?? itemAddedToCart?.quantity ?? 1);

  const certonaOptions =
    isUseDealsCart && !skuId
      ? {
          certonaType: certonaPageType.dealsDrawer,
          skuIds: [...miniCartDealData].join(';'),
          vehicleID: vehicleId,
        }
      : {
          certonaType: certonaPageType.cartAdd,
          skuIds: skuId,
          vehicleID: vehicleId,
          isRecItemAddedToCart:
            (certonaRecommendationAddedToCart && certonaAddToCartEnabled) ||
            productDetails.certonaItemAddedTocart,
          parttype: !!certonaRecommendationAddedToCart
            ? formatPartTypeId(itemAddedToCart?.originalPartTypeId?.toString())
            : formatPartTypeId(productDetails.productPartType?.toString()),
        };
  const dependencyArray = isUseDealsCart
    ? [vehicleId, isUseDealsCart, skuId]
    : [skuId, vehicleId, isMiniCartFlyoutEnabled];
  const shouldExecuteCertonaCall = isUseDealsCart
    ? isDealsDrawerMiniCartEnabled
    : isMiniCartFlyoutEnabled;

  const certonaRecommendations = useGetCertonaRecommendations(
    certonaOptions,
    dependencyArray,
    shouldExecuteCertonaCall
  );

  const prevCertonaRecommendations = usePrevious(certonaRecommendations);

  useEffect(() => {
    if (!!certonaRecommendations && certonaRecommendations !== prevCertonaRecommendations) {
      setHasLoadedCertona(true);
    }
  }, [certonaRecommendations, prevCertonaRecommendations]);

  const renderEachItemPrice = () => {
    const amt = miniCartItem?.lineItemPriceInfo.unitPrice;

    if (total < 2) {
      return;
    }

    const isBopusEnabled = storeDetails?.bopusEnabled ?? false;
    const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
    const eachLabel = isBopusMexicoStoreEnabled ? (
      <Label label="label_cart_Each" />
    ) : (
      <Label label="label_cart_lineItem_Each" />
    );

    if (total > 1 && amt) {
      return (
        <div
          className={cx(
            azCommonStyles['az-body-3-regular'],
            azCommonStyles['az-margin-top-4xs'],
            azCommonStyles['az-align-center'],
            styles.unitPrice
          )}
        >
          ${formatPrice(amt)} {eachLabel}
        </div>
      );
    }
  };

  const splitNumber = (price: number) => {
    return String(price.toFixed(2)).split('.');
  };

  const renderPricePart = () => {
    const isDiscounted = miniCartItem?.lineItemPriceInfo.discounted ?? false;
    const retailPrice = miniCartItem?.lineItemPriceInfo.retailPrice ?? 0;
    const dealAppliedAmount = miniCartItem?.lineItemPriceInfo.dealAppliedAmount ?? 0;
    const discountedPrice = retailPrice && dealAppliedAmount ? retailPrice - dealAppliedAmount : 0;
    const corePriceValue = skuIdFromProductDetail
      ? skuDetailsResult.data?.[0].skuPricingAndAvailability.corePrice ?? 0
      : addedProductDetails.productAvailabilityInfo.skuPricingAndAvailability?.corePrice ??
        miniCartItem?.lineItemPriceInfo.corePrice ??
        0;

    let priceComponent = null;

    if (isDiscounted && discountedPrice) {
      priceComponent = (
        <PriceComponent
          priceType="secondary"
          wasDealPrice={splitNumber(retailPrice)}
          dealPrice={splitNumber(discountedPrice)}
          wasVariant="rightAlign"
          corePriceValue={corePriceValue}
          miniCartFly={true}
          enableStrikeThru={false}
        />
      );
    } else if (retailPrice) {
      priceComponent = (
        <PriceComponent
          wrapperClassName={
            miniCartItem?.productInfo.clearanceItem ? styles.priceComponentWrapper : ''
          }
          priceType="secondary"
          dealPrice={splitNumber(retailPrice)}
          corePriceValue={corePriceValue}
          miniCartFly={true}
          enableStrikeThru={false}
        />
      );
    }

    return (
      <>
        {priceComponent}
        {miniCartItem?.productInfo.clearanceItem && (
          <div className={styles.clearance}>
            <Label label="label_clearance_badge" />
          </div>
        )}
      </>
    );
  };

  const renderCoreDeposit = () => {
    const corePriceValue = skuIdFromProductDetail
      ? skuDetailsResult.data?.[0].skuPricingAndAvailability.corePrice ?? 0
      : !!itemAddedToCart
      ? miniCartItem?.lineItemPriceInfo.corePrice ?? 0
      : addedProductDetails.productAvailabilityInfo.skuPricingAndAvailability?.corePrice ?? 0;

    if (corePriceValue > 0) {
      return (
        <div
          className={`${cx(azCommonStyles['az-caption'], styles.coreDeposit)} ${
            locale === countryCodes.mx ? styles.coreDepositMx : ''
          }`}
        >
          +$
          {formatPrice(corePriceValue)}
          {` `} {labels.lblMiniCartCorePrice}
        </div>
      );
    }
  };

  const updateMiniCartItem = (item?: AddToCartData & { orderType?: string }) => {
    miniCartDealData?.clear();
    // @ts-expect-error fix type of itemAddedToCart
    setItemAddedToCart(item);
    setCertonaRecommendationAddedToCart(true);
  };

  return (
    <>
      <div className={styles.miniCartContainer}>
        <NavBar
          classes={{
            mainContainer: styles.navBarContainer,
            closeIconBtn: styles.navBarCloseIcon,
          }}
          showBackButton={false}
          showCloseButton
          handleClose={props.handleClose}
        />
        {isFetchingData ? (
          <div className={styles.loadingSpinner}>
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div
              className={
                isDeal
                  ? cx(styles.productContainer, styles.productContainerBorderLine)
                  : styles.productContainer
              }
            >
              <div data-testid="minicart-flyout-added-to-cart" className={styles.displayFlex}>
                <div className={(cx(azCommonStyles['az-margin-top-s']), styles.checkMark)}>
                  <Image
                    src={imageConstant.greenTickRoundedMA.src}
                    width={20}
                    height={20}
                    className={styles.addVehicleWarning}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className={cx(azCommonStyles.ml6, styles.addedToCart)}>
                    {labels.lblMiniCartAddedToCart}
                  </h2>
                </div>
              </div>
              {isDeal ? (
                <div className={styles.dealImagesContainer}>
                  {Array.from(miniCartDealData).map((skuId) => {
                    const dealItem = findMiniCartDataBySku(skuId);
                    return (
                      <MiniCartFlyoutDealImage
                        key={skuId}
                        imageUrl={dealItem.itemImage}
                        quantity={dealItem.quantity}
                      />
                    );
                  })}
                </div>
              ) : (
                <MiniCartFlyoutItemCard
                  addedProductImage={addedProductImage}
                  addedProductTitle={addedProductTitle}
                  quantityLabel={labels.lblMiniCartQty}
                  total={total}
                  renderCoreDeposit={renderCoreDeposit}
                  renderEachItemPrice={renderEachItemPrice}
                  renderPricePart={renderPricePart}
                />
              )}
            </div>
            {miniCartData && (
              <MiniCartFlyoutCheckout
                miniCartData={props.orderDetailsFromState.orderItems}
                miniCartMap={miniCartData}
                handleClose={props.handleClose}
              />
            )}
            {locale === countryCodes.enUS && (
              <CertonaBlock
                LoadingSkeleton={StackedPartCardSkeleton}
                className={styles.certonaMiniCartFlyout}
                contentItem={
                  isUseDealsCart && !skuId ? certonaDealsMinicartObj : certonaMinicartObj
                }
                certonaRecommendations={
                  locale === countryCodes.us ? certonaRecommendations : undefined
                }
                checkContainerNameIncludes
                containerType={'horizontal_stacked'}
                onCertonaAddToCartClick={(addToCartData) => {
                  miniCartDealData?.clear();
                  setIsFetchingData(true);
                  setCertonaRecommendationAddedToCart(true);
                  updateMiniCartItem({
                    ...addToCartData,
                    orderType:
                      addToCartData.fulFillmentId === FULFILLMENT_METHODS.ONLINEORDER
                        ? 'ONLINEORDER'
                        : 'STOREORDER',
                  });

                  setIsFetchingData(false);
                  trackAddToCartCertonaCartridge(addToCartData, '', isUseDealsCart);
                  setTimeout(() => {
                    props?.parentRef?.current?.scrollTo(0, 0);
                  }, 500);
                }}
                updateAfterAddtoCartClick={updateMiniCartItem}
                inDrawer
                onlyShowAvailableProductRecommendations={hasLoadedCertona}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
