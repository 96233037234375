/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { AddToCartData } from '@/types';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackAddToCartCertonaCartridge = (
  addToCartData: AddToCartData,
  blockId?: string,
  isDealsDrawerMiniCart = false
) => {
  const { pageName = '', pageType = '', siteSection = '' } = window.utag_data ?? {};
  let customLinkName = isDealsDrawerMiniCart
    ? 'Deals Drawer Mini-Certona:Add_to_Cart'
    : `${pageType}-Certona:Add_to_Cart`;

  if (blockId === 'certona-threshold') {
    customLinkName = customLinkName.concat(` - Threshold`);
  }

  clickTrack({
    customLinkName,
    eventType: 'addToCart',
    productRecommendationTypeList: 'Certona',
    productBrandList: [addToCartData.brandName],
    productFulfillmentTypeList: [addToCartData.fulFillmentId],
    productIdList: [addToCartData.skuId],
    productPriceList: [addToCartData.price],
    originalPartTypeIdList: [addToCartData.originalPartTypeId],
    productPartNumberList: [addToCartData.productPartNumber],
    productRepositoryIdList: [addToCartData.productRepositoryId],
    pageName,
    pageType,
    siteSection,
  });
};
