/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import { View, Skeleton } from '@az/starc-ui';
import styles from './styles.module.scss';

const PartCard = () => (
  <View borderRadius="small" borderColor="disabled" padding={2} gap={4} direction="row">
    <View>
      <NextImage
        alt="placeholder-product-image"
        src="/images/image-placeholder-skeleton-load.svg"
        height={68}
        width={64}
      />
    </View>
    <View.Item grow>
      <View gap={2}>
        <Skeleton width="50%" height="24px" />
        <Skeleton height="18px" />
      </View>
    </View.Item>
  </View>
);

export const StackedPartCardSkeleton = () => {
  return (
    <View backgroundColor="secondary" padding={4} gap={4} className={styles.skeletonContainer}>
      <Skeleton height="27px" />
      <View gap={2} attributes={{ style: { margin: 0 } }}>
        {new Array(6).fill(1).map((_, i) => (
          <PartCard key={i} />
        ))}
      </View>
    </View>
  );
};
