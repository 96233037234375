/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Image, { pureLoader } from '@/components/NextImage';
import DealItemQuantityBadge from './MiniCartFlyoutDealItemQuantityBadge';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  imageUrl: string;
  quantity: string;
};
const MiniCartFlyoutDealImage = ({ imageUrl, quantity }: Props) => {
  return (
    <div
      data-testid="miniCartFlyoutDealImageContainer"
      className={
        parseInt(quantity) <= 1 ? styles.dealImage : cx(styles.dealImage, styles.badgeMargin)
      }
    >
      <Image src={imageUrl} alt="filter" width={40} height={40} loader={pureLoader} />
      {parseInt(quantity) > 1 && <DealItemQuantityBadge quantity={quantity} />}
    </div>
  );
};

export default MiniCartFlyoutDealImage;
