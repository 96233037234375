/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './styles.module.scss';

type Props = {
  quantity: string;
};
const DealItemQuantityBadge = ({ quantity }: Props) => {
  return (
    <span data-testid="dealItemQuantityBadge" className={styles.dealItemQuantityBadgeContainer}>
      {quantity}
    </span>
  );
};

export default DealItemQuantityBadge;
