/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import { Button } from '@/components/Button';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { type CartSummary } from '@/features/header';
import FreeShippingNote from '@/components/AZCustomComponent/FreeShippingNote';
import { useFeatureFlag } from '@/features/globalConfig';
import { cartConstants } from '@/constants/cart';
import type { OrderItemsFromState } from '@/types/reduxStore/orders';
import { formatPrice } from '@/utils/validator/formatPrice';

type Props = {
  miniCartData: OrderItemsFromState | undefined;
  miniCartMap: CartSummary;
  handleClose: () => void;
};

const labelsMap = {
  lblMiniCartSubTotal: 'label_mini_cart_sub_total',
  lblMiniCartContinueShop: 'label_mini_cart_continue_shopping',
  lblMiniCartItem: 'label_mini_cart_item',
  lblMiniCartItems: 'label_mini_cart_items',
};

const MiniCartFlyoutCheckout = ({ miniCartData, miniCartMap, handleClose }: Props) => {
  const labels = useLabels(labelsMap);
  const router = useRouter();
  const { itemCount, orderSubTotal } = miniCartMap;
  const freeShippingMargin: string | undefined = miniCartData?.[0]?.freeShippingMargin;
  const orderType: string | undefined = miniCartData?.[0]?.orderType;
  const showFreeShipMsg: boolean | undefined = miniCartData?.[0]?.showFreeShipMsg;
  const isOnlineOrder = orderType === cartConstants.ONLINEORDER;
  const subTotal = formatPrice(orderSubTotal);
  const checkNumber = itemCount > 1 ? labels.lblMiniCartItems : labels.lblMiniCartItem;
  const IS_MINI_CART_FREE_SHIP_NOTE_ENABLED =
    useFeatureFlag('IS_MINI_CART_FREE_SHIP_NOTE_ENABLED') === 'true';
  const isShowFreeShippingNote =
    IS_MINI_CART_FREE_SHIP_NOTE_ENABLED && isOnlineOrder && showFreeShipMsg;

  return (
    <div
      className={
        isShowFreeShippingNote
          ? styles.wrapperContainerWithShippingPromo
          : styles.wrapperCheckoutContainer
      }
    >
      {isShowFreeShippingNote && (
        <div className={styles.freeShippingNote}>
          <FreeShippingNote freeShippingMargin={freeShippingMargin} />
        </div>
      )}
      <div className={styles.cartCheckoutLabel}>
        <div
          className={
            isShowFreeShippingNote ? styles.miniCartTextWithShippingPromo : styles.miniCartText
          }
        >
          {`${labels.lblMiniCartSubTotal}: $${subTotal}`}
          <span className={styles.itemListText}>{`(${itemCount} ${checkNumber})`}</span>
        </div>
      </div>

      <div className={styles.cartTotalCheckoutBtn}>
        <Button
          variant="super"
          type="submit"
          fullWidth
          onClick={() => {
            void router.push(routePaths.cart);
          }}
          data-testid="view-cart-and-checkout-button"
        >
          <Label label="button_confirmation_summary_ViewCartAndCheckout" />
        </Button>
      </div>
      <div className={styles.continueShoppingLink}>
        <button onClick={handleClose}>{labels.lblMiniCartContinueShop}</button>
      </div>
    </div>
  );
};

export default MiniCartFlyoutCheckout;
