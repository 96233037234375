/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { IconButton } from '@/components/IconButton/IconButton';
import { useLabel } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import KeyboardBackspaceIcon from '@/public/images/keyboard-backspace.svg';
import CloseIcon from '@/public/images/close.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import styles from './styles.module.scss';

type Props = {
  showBackButton?: boolean;
  showCloseButton?: boolean;
  handleBack?: () => void;
  handleClose?: () => void;
  classes?: { mainContainer?: string; closeIconBtn?: string };
};

export function NavBar({
  showBackButton,
  showCloseButton,
  handleClose,
  handleBack,
  classes = {},
}: Props) {
  const closeRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (showCloseButton && closeRef.current) {
      closeRef.current.focus();
    }
  }, [showCloseButton]);
  return (
    <div
      className={cx(styles.drawerNavBarMainContainer, {
        [classes.mainContainer || '']: classes.mainContainer,
      })}
    >
      <div className={styles.leftControls}>
        {showBackButton ? <BackButton handleBack={handleBack} /> : null}
      </div>
      {showCloseButton ? (
        <CloseButton
          closeRef={closeRef}
          handleClose={handleClose}
          closeBtnClass={classes.closeIconBtn}
        />
      ) : null}
    </div>
  );
}

function BackButton({ handleBack }: { handleBack?: () => void }) {
  const labels = {
    label_back: useLabel('label_back'),
  };

  return (
    <Button className={styles.backButton} data-testid="back-button" onClick={handleBack}>
      <KeyboardBackspaceIcon className={cx(iconStyles.defaultIconStyle, styles.backIcon)} />
      {labels.label_back}
    </Button>
  );
}

function CloseButton({
  handleClose,
  closeRef,
  closeBtnClass,
}: {
  closeRef: React.Ref<HTMLButtonElement>;
  handleClose?: () => void;
  closeBtnClass?: string;
}) {
  return (
    <IconButton
      className={cx({
        [closeBtnClass || '']: closeBtnClass,
      })}
      ref={closeRef}
      aria-label="Close"
      onClick={handleClose}
      data-testid="modal-close-btn"
    >
      <CloseIcon className={cx(iconStyles.defaultIconStyle, styles.closeIcon)} />
    </IconButton>
  );
}
