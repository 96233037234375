/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Image, { pureLoader } from '@/components/NextImage';
import styles from './styles.module.scss';

type Props = {
  addedProductImage: string;
  addedProductTitle: string;
  quantityLabel: string;
  total: number;
  renderEachItemPrice: () => JSX.Element | undefined;
  renderPricePart: () => JSX.Element;
  renderCoreDeposit: () => JSX.Element | undefined;
};
const MiniCartFlyoutItemCard = ({
  addedProductImage,
  addedProductTitle,
  quantityLabel,
  total,
  renderCoreDeposit,
  renderEachItemPrice,
  renderPricePart,
}: Props) => {
  return (
    <div className={styles.wrapperContainer}>
      <div data-testid="mini-cart-flyout-item-container" className={styles.wrapperItems}>
        <div className={styles.image}>
          <Image src={addedProductImage} alt="filter" width={40} height={40} loader={pureLoader} />
        </div>
        <div className={styles.description}>{addedProductTitle}</div>
        <div className={styles.priceQuantityWrapper}>
          <div data-testid="mini-cart-flyout-quantity" className={styles.quantity}>
            {quantityLabel} <span>{total}</span>
          </div>
          <div className={styles.eachPrice}>{renderEachItemPrice()}</div>
          <div className={styles.strikeThroughPrice}>{renderPricePart()}</div>
          <div className={styles.coreDeposit}>{renderCoreDeposit()}</div>
        </div>
      </div>
    </div>
  );
};

export default MiniCartFlyoutItemCard;
